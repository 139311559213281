import React from "react";
import { Typography, Paper } from "@material-ui/core";
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@material-ui/lab";



function Experience() {

  return (
    <div>

      
      <Timeline align="alternate">
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
              JAN 2023 - PRESENT
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            < TimelineDot style={{backgroundColor: "#0058f7"}}>
        
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}} >
              <h3 style={{fontFamily: "'Courier New', Courier, monospace", fontWeight: "900", marginBottom: "0",  }}>
                {" "}
                Co-Founder & CTO{" "}
              </h3>{" "}
              <a
                href="https://fictivelabs.com/"
                style={{ fontStyle: "italic", fontWeight: "500", color: "#0058f7" }}
              >
                fictive labs
              </a>         
              <h4
                style={{
                  fontFamily: "'Courier New', Courier, monospace",
                  fontStyle: "italic",
                  fontWeight: "500",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
              </h4>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", color:"#777", fontWeight: "700"}}>
                • Co-founder and chief technical officer for Ficitve, a centralized, literary, platform where users can publish and receive 
                direct incentives for the work that they publish.  
              </Typography>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", color:"#777", fontWeight: "700"}}>
                • Led frontend development using React and React Native, ensuring a seamless user experience.
              </Typography>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", color:"#777", fontWeight: "700"}}>
                • Successfully integrated Google Cloud Platform (GCP) for a scalable and secure backend infrastructure.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent>
            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
              SEP 2021 - PRESENT
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            < TimelineDot style={{backgroundColor: "#0058f7"}}>
        
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}} >
              <h3 style={{fontFamily: "'Courier New', Courier, monospace", fontWeight: "900", marginBottom: "0",  }}>
                {" "}
                Smart Contract & Blockchain Developer{" "}
              </h3>{" "}
              <a
                href="https://www.snowball.network/"
                style={{ fontStyle: "italic", fontWeight: "500", color: "#0058f7" }}
              >
                snowball
              </a>
              <br></br>
              <a
                href="https://contrax.finance/"
                style={{ fontStyle: "italic", fontWeight: "500", color: "#0058f7" }}
              >
                contrax
              </a>
              <h4
                style={{
                  fontFamily: "'Courier New', Courier, monospace",
                  fontStyle: "italic",
                  fontWeight: "500",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                Lead Developer
              </h4>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", color:"#777", fontWeight: "700"}}>
                • Built smart contracts for the Snowball DeFi protocol that was 
                the first auto-compounder and stable-asset exchange built on the 
                Avalanche network, as well as acted as lead developer for the Arbitrum based DAO, Contrax.  
              </Typography>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", color:"#777", fontWeight: "700"}}>
                • Integrated different DeFi platforms and automated their harvesting 
                process so that investors can aggregate their rewards on a multitude 
                of platforms.
              </Typography>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", color:"#777", fontWeight: "700"}}>
                • Wrote, tested, and deployed contracts using solidity, hardhat, 
                typescript, and the ethers library.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent>
            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
              JUNE 2020 - MARCH 2021
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            < TimelineDot style={{backgroundColor: "#0058f7"}}>
                
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}}>
              <h3 style={{fontFamily: "'Courier New', Courier, monospace", fontWeight: "900", marginBottom: "0",  }}>
                {" "}
                Co-Founder & Lead Developer of the Launch Learning Platform,{" "}
              </h3>{" "}
              <a
                href="https://launchlearningplatform.com/"
                style={{ fontStyle: "italic", fontWeight: "500", color: "#0058f7" }}
              >
                launch learning platform
              </a>
              <h4
                style={{
                  fontStyle: "italic",
                  fontWeight: "500",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                Co-Founder and Lead Developer
              </h4>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700", color:"#777"}}>
                • Founded and developed a non-profit virtual learning platform to 
                increase education accessibility to high school students within the 
                Caribbean during the pandemic.
              </Typography>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700", color:"#777"}}>
                • Built and maintained the web application through JavaScript, HTML, 
                CSS & React.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent>
            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
              JAN 2020 - APRIL 2020
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            < TimelineDot style={{backgroundColor: "#0058f7"}}>
                
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}}>
              <h3 style={{fontFamily: "'Courier New', Courier, monospace", fontWeight: "900", marginBottom: "0",  }}>
                {" "}
                Mathematical Biology Research, Dartmouth College
              </h3>
              <h4
                href=""
                style={{ fontStyle: "italic", fontWeight: "500" }}
              >
                Fu Evolutionary Dynamics Lab
              </h4>
              <h4
                style={{
                  fontStyle: "italic",
                  fontWeight: "500",
                  marginBottom: "5px",
                }}
              >
                Mathematical Biology Research Assistant
              </h4>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700", color:"#777"}}>
                • Collaborated with 2 members of the Thayer School of Engineering to simulate 
                mathematical thermal models in MATLAB  for the analysis of the hierarchical 
                organisation of tumour initiating cells in response to chemical and radioactive 
                treatment of cancer patients.
              </Typography>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700", color:"#777"}}>
                • Analyzed results and presented research findings to 25 distinguished faculty 
                members and students. 
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent>
            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
              SEP 2018 - JUNE 2020
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            < TimelineDot style={{backgroundColor: "#0058f7"}}>
                
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}}>
              <h3 style={{fontWeight: "900", marginBottom: "5px" }}>
                {" "}
                Physics Tutor, Dartmouth College, USA
              </h3>
              <h4
                 style={{
                  fontFamily: "'Courier New', Courier, monospace",
                  fontStyle: "italic",
                  fontWeight: "500",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                Tutor
              </h4>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700", color:"#777"}}>
                Aided first and second year students with introductory physics
                coursework.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent>
            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
              MAY 2019 - AUG 2019
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            < TimelineDot style={{backgroundColor: "#0058f7"}}>
                
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}}>
              <h3 style={{fontWeight: "900", marginBottom: "5px" }}>
                {" "}
                Baobab Experience, Rome Italy
              </h3>

              <h4
                 style={{
                  fontFamily: "'Courier New', Courier, monospace",
                  fontStyle: "italic",
                  fontWeight: "500",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                Web Design Intern
              </h4>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700", color:"#777"}}>
                • Designed web radio application on a team of 5 for the dissemination 
                and translation of safety information to incoming and existing immigrant 
                groups across Europe given the political climate.
              </Typography>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700", color:"#777"}}>
                • Served as a recruiter and translator for 15﹢immigrants to the existing 
                Baobab team in order to determine how to best serve their needs.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent>
            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
              SEP 2018 - MAY 2019
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            < TimelineDot style={{backgroundColor: "#0058f7"}}>
                
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}}>
              <h3 style={{fontWeight: "900", marginBottom: "5px" }}>
                {" "}
                Great Issues Scholar Coordinator
              </h3>

              <h4
                href=""
                style={{fontStyle: "italic", fontWeight: "700" }}
              >
                Dickey Center, Dartmouth College, USA
              </h4>

              <h4
                 style={{
                  fontFamily: "'Courier New', Courier, monospace",
                  fontStyle: "italic",
                  fontWeight: "500",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                Coordinator
              </h4>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700", color:"#777"}}>
                • Served as an elected official that managed risks and opportunities 
                of the over 4000 undergraduate student body by aligning Dartmouth's 
                allotted funding with the functional and technical requirements of 
                the Great Issues Scholar.
              </Typography>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700", color:"#777"}}>
                • Translated Dartmouth's Board of Trustees' financial requirements 
                into over 16 different advanced projects to increase engagement between 
                the student body and world leaders.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent>
            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
              JUNE 2017 - AUG 2017
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            < TimelineDot style={{backgroundColor: "#0058f7"}}>
                
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}}>
              <h3 style={{fontWeight: "900", marginBottom: "5px" }}>
                World Justice Project, Washington, DC
              </h3>

              <h4
                 style={{
                  fontFamily: "'Courier New', Courier, monospace",
                  fontStyle: "italic",
                  fontWeight: "500",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                Rule of Law Index Intern
              </h4>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700", color:"#777"}}>
                • Created new metric systems through quantifying information
                gathered from research which largely contributed to the
                efficiency of data analysis for the annual report series.
              </Typography>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700", color:"#777"}}>
                • Built, enhanced and reported on the qualitative research on
                rule of law developments around the world to support business
                decisions and strategy
              </Typography>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700", color:"#777"}}>
                • Served as a member of the Qualified Respondent’s
                Questionnaires (QRQ) team tasked with recruiting experts around
                the world in the fields of Commercial Law, Criminal Justice,
                Labour Law, and Public Health, to create the annual rule of law
                index.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent>
            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
              JULY 2012 - AUG 2012
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            < TimelineDot style={{backgroundColor: "#0058f7"}}>
                
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}}>
              <h3 style={{ fontWeight: "900", marginBottom: "5px" }}>
                Department of Basic Medical Sciences Pharmacology at the UWI,
                Kingston, Jamaica{" "}
              </h3>

              <h4
                 style={{
                  fontFamily: "'Courier New', Courier, monospace",
                  fontStyle: "italic",
                  fontWeight: "500",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                Research Assistant
              </h4>
              <Typography variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700", color:"#777"}}>
                Performed statistical data analysis to quantify animal
                behavioural response to different stimulants.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>
  );
}

export default Experience;
