import React, {useState} from 'react';
import './SubmitForm.css';
import { Button } from '@material-ui/core'



function SubmitForm() {
    const [status, setStatus] = useState("Submit");
    const [state, setState] = useState({
        message: '',
        name: '',
        subject:'',
        email:'',
      });
    
    const { message, name, subject, email } = state;


    const handleState = ({ target: { id, value } }) => {
        setState({ ...state, [id]: value });
    }

    return (
       <form className ="email__send" 
       action="https://formsubmit.co/fb1cf419326726b761372fd5aa3ee45b" 
       method="POST"
       >
            
            <div className ="email__info">
            
                <input className = "inputBox" type ="text" placeholder = "name" name="name" id="name" required onChange={handleState} value={name}/>
                <input className = "inputBox1" type ="email" placeholder = "email address" id="email" name="email" required onChange={handleState} value={email}/>
            </div>

            <div className="email__info3">
                <input className = "subjectBox" type="text" placeholder= "subject" name="_subject" required id="subject" onChange={handleState} value={subject} />
            </div>

            <div className="email__info2">
                <textarea className="inputBox2" placeholder="message" name="name" required id="message" onChange ={handleState} value={message}/> 
                <Button className="button" type="submit" value={'Send Email'}>{status}</Button>
            </div>

            <input type="hidden" name="_next" value="http://abigailcameron.com/#contact"></input>
            <input type="hidden" name="_captcha" value="false"></input>
            
            


       </form>

         
            
      
    )
}

export default SubmitForm