import React from "react";
import "./Code.css";
import CodeIcon from "@material-ui/icons/Code";
import BuildIcon from "@material-ui/icons/Build";

function Code() {
  return (
    <div className="code">
      <div className="language">
        
        <div className="language__font">
          <div className="head">
            <h2>Languages</h2>
            <CodeIcon fontSize="default" />
          </div>
          <h3>Solidity <span class="level">•••••</span></h3> 
          <h3>TypeScript <span class="level">•••••</span> </h3>
          <h3>JavaScript <span class="level">•••••</span></h3>
          <h3>CSS <span class="level">•••••</span></h3>
          <h3>HTML <span class="level">••••</span></h3>
          <h3>Java <span class="level">•••</span></h3>
          <h3>Swift <span class="level">••</span></h3>
          <h3>Python <span class="level">••</span></h3>
          <h3>Objective-C <span class="level">•</span></h3>
         
        </div>
      </div>

      <div className="tools">
        <div className="tools__font">
          <div className="head">
            <h2>Tools + Frameworks</h2>
            <BuildIcon fontSize="default" />
          </div>
          <h3>React <span class="level">•••••</span></h3>
          <h3>React native <span class="level">•••••</span></h3>
          <h3>Hardhat <span class="level">•••••</span></h3>
          <h3>GitHub <span class="level">•••••</span></h3>
          <h3>Ethers.js <span class="level">••••</span></h3>
          <h3>Node.js <span class="level">•••</span></h3>
          <h3>Web3.js <span class="level">•••</span></h3>
          <h3>Next.js <span class="level">•••••</span></h3>
          <h3>Tailwind CSS <span class="level">••••</span></h3>
        </div>
      </div>
    </div>
  );
}

export default Code;
