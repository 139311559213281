import React, {useState} from 'react';
import "./Header.css";
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';
import MenuButton from './MenuButton';



function Header() {
    const [show, setShow] = useState(false);
    return (
        <div >
               
                <div className ="header">
                    <div className = "logo__container">
                        <a href = "#home">
                            <div className = "logo">
                                &lt; abigail cameron / &gt; 
                            </div>
                        </a>
                    </div>
            
                    <div className ="header__center">
                        <a href ="#home">Home</a>              
                        <a href = "#about">About</a>
                        <a href ="#resume">Resumé</a>
                        <a href ="#portfolio">Portfolio</a>
                        <a href ="#skills">Skills</a>
                        <a href ="#contact">Contact</a>
                    </div>

                    <div className ="header__left">
                        <a href ="https://www.linkedin.com/in/abigailtcameron/"><LinkedInIcon/>    </a>
                        <a href ="https://github.com/AbigailTCameron"><GitHubIcon/></a>
                    </div>
                </div>

                <div className="squeeze" > 
                    <MenuButton onClick= {(e)=> setShow(!show)}/> 
                    {show === true ? 
                    
                    <div className ="dropdown">
                        <a href ="#home"><h1>Home</h1></a>              
                        <a href = "#about">< h1>About</h1></a>
                        <a href ="#resume"><h1>Resumé</h1></a>
                        <a href ="#portfolio"><h1>Portfolio</h1></a>
                        <a href ="#skills"><h1>Skills</h1></a>
                        <a href ="#contact"><h1>Contact</h1></a>
                        <a href ="https://www.linkedin.com/in/abigailtcameron/"><LinkedInIcon/></a>
                        <a href ="https://github.com/AbigailTCameron"><GitHubIcon/></a>
                    </div> :null}
                </div>
        </div>


    )
}

export default Header
