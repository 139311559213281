import React from 'react'
import './ResumeObject.css'


function ResumeObject({title, active, ...props}) {
    return (             
    

            <div className = {`resume__container ${active && "resume__container--selected"}`}
                onClick = {props.onClick}>
                <h1>{title}</h1>
                
            </div>

    )
}

export default ResumeObject
