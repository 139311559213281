import React from 'react';
import './PortfolioButton.css';

function PortfolioButton({title, active, ...props}) {
    return (
        <div className={`single__button ${active && "single__button--selected"}`}
        onClick={props.onClick}>
            <h5>{title}</h5>
            
        </div>
    )
}

export default PortfolioButton
