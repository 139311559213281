import React from 'react';
import MenuIcon from '@material-ui/icons/Menu';
import './MenuButton.css';

function MenuButton({...props}) {
    return (
        <div className="icon">
            <MenuIcon 
            onClick ={props.onClick}
            fontSize="large" />
            
        </div>
    )
}

export default MenuButton
