import React from 'react';
import './ContactForm.css';
import SubmitForm from './SubmitForm';

function ContactForm() {

    
    return (
        <div className ="contact" id="contact">
            <h1>Contact</h1>

            <h3>Send messages directly to my inbox.</h3>

            <div className="contact__details">
            
                
                {/* <div className="email">
                  <EmailIcon />  <a href = "mailto:abigailcameronportfolio@gmail.com" style ={{color:"#ff7549",fontSize:"20px", fontWeight: "bold"}}>abigailcameronportfolio@gmail.com</a>
                </div> */}
            </div>

            <SubmitForm />

            
        </div>
    )
}

export default ContactForm