import React from "react";
import "./Home.css";

function Home() {
  return (
    <div className="home" id="home">
      Abigail Cameron
      <h1>
        Engineer who believes in <span class="text-span">mathematical modelling </span> to build better systems. 
      </h1>
    </div>
  );
}

export default Home;
