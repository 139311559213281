import React from "react";
import "./Other.css";
import FlagIcon from "@material-ui/icons/Flag";
import LinkIcon from "@material-ui/icons/Link";
import PublicIcon from "@material-ui/icons/Public";

function Other() {
  return (
    <div className="other">
      <div className="languages">
        
        <div className="languages__individual">
          <div className="head">
            <h2>Languages</h2>
            <FlagIcon fontSize="default" />
          </div>
          <h3>English <span class="level">•••••</span></h3>
          <h3>Jamaican Creole <span class="level">•••••</span></h3>
          <h3>Italian <span class="level">•••</span></h3>
          <h3>French <span class="level">•</span></h3>
          <h3>Portuguese <span class="level">•</span></h3>
        </div>
      </div>

      <div className="key__skills">
      
        <div className="key__individual">
          <div className="head">
            <h2>Key Skills</h2>
            <LinkIcon fontSize="default" />
          </div>
        
          <h3>Report Writing <span class="level">•••••</span></h3>
          <h3>Problem-solving <span class="level">••••</span></h3>
          <h3>Written and Verbal Communication <span class="level">••••</span></h3>
          <h3>Networking <span class="level">•••</span></h3>
          <h3>Volunteer <span class="level">•••</span></h3>
          <h3>Adaptability <span class="level">••••</span></h3>
        </div>
      </div>

      <div className="other__skills">
        
        <div className="other__individual">
          <div className="head">
            <h2>Other</h2>
            <PublicIcon fontSize="default" />
          </div>
          <h3>Excel <span class="level">•••••</span></h3>
          <h3>Word <span class="level">•••••</span></h3>
          <h3>Powerpoint <span class="level">•••••</span></h3>
        </div>
      </div>
    </div>
  );
}

export default Other;
