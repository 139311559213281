import React from "react";
import "./Mobile.css";
import PhoneVideoCard from "./PhoneVideoCard";

function Mobile() {
  return (
    <div className="mobile__app">
      <PhoneVideoCard
        props2 = {() => window.location.href = "https://github.com/AbigailTCameron/Signal-App-Clone"}
        active2 = {true}
        
        url="signal-app.mp4"
        description="Signal App Clone"
      />
    </div>
  );
}

export default Mobile;
