import React, {useState} from 'react';
import './Resume.css';
import Education from './Education';
import Experience from './Experience';
import Honour from './Honour';
import ResumeObject from './ResumeObject'




function Resume() {

    const [title, setTitle] = useState("Education");

   


    return (
        <div className = "resume" id="resume">
            <h1>Resumé</h1>

            <div className ="container">
                <div className ="content__divider">

                        <ResumeObject 
                        onClick={(e) => setTitle("Education")}
                        title="Education" 
                        active = {title==="Education"}
                        
                        /> 
                        <ResumeObject 
                        onClick={(e) => setTitle("Experience")}
                        title="Experience"
                        active ={title==="Experience"}
                        /> 
                        
                        <ResumeObject 
                        onClick={(e) => setTitle("Honours + Awards")}
                        title="Honours + Awards"
                        active={title==="Honours + Awards"}
                        /> 

                </div>


                <div className="resume__info">
                    {title ==="Education" && <Education /> }
                    {title ==="Experience" && <Experience />}
                    {title ==="Honours + Awards" && <Honour/>}
                </div>

            </div>

           

         
           

            
            
        </div>
    )
}

export default Resume
