import React from 'react';
import './App.css';
import Header from './menu/Header';
import Home from './home/Home';
import About from './about/About';
import Resume from './resume/Resume';
import Portfolio from './portfolio/Portfolio';
import Skills from './skills/Skills';
import ContactForm from './contact/ContactForm';
import SideMenu from './menu/SideMenu';

function App() {
  return (
    <div className="app">
      
      <SideMenu/>
      <Header />
      <Home /> 
      <About />
      <Resume />
      <Portfolio /> 
      <Skills />
      <ContactForm />
  
    </div>
  );
}

export default App;
