import React from "react";
import "./SectionButton.css";

function SectionButton({ title, active, ...props }) {
  return (
    <div
      className={`click__button ${active && "click__button--selected"}`}
      onClick={props.onClick}
    >
      <h2>{title}</h2>
    </div>
  );
}

export default SectionButton;
