import React from 'react';
import './Research.css';
import SlideCard from './SlideCard';



function Research() {
    return (
        <div className="research__object">
            <SlideCard 
                url="https://docs.google.com/presentation/d/e/2PACX-1vRt62MbT-ypotbMmJXsOE8Se2xLJhA9lCrsdMFhuWCAuoWjnOom_ftGD1C6HsH7Av6aNxnlxLQwpMRd/embed"
                description="Mathematical Biology Research - simulated in MATLAB"
            />


        </div>
    )
}

export default Research