import React from 'react';
import './SkillObject.css';

function SkillObject({skill, active, ...props}) {
    return (
    
            <div className ={`skill__container ${active && "skill__container--selected"}`}
                onClick = {props.onClick}>
                <h1>{skill}</h1>
            </div>
            
        
    )
}

export default SkillObject
