import React from "react";
import "./About.css";

function About() {
  return (
    <div className="about" id="about">
      <h1>About</h1>

      <div className="about__info">
        <div className="pic__container">
          <img className="about__pic" src="/about.jpg" alt="" />
        </div>

        <div className="about__stuff">
          <h1>Background</h1>
        
          <h3>
            I was born and raised in Kingston, Jamaica, and have
            lived and travelled extensively to places like Washington D.C., Sao Paulo, 
            and Rome, Italy. Before my journey in coding I explored many different arenas. 
            I started out studying Pre-Med at Campion College, in Kingston, Jamaica, where 
            I eventually got into Medical school but decided to defer to study Mathematics at
            Dartmouth College. It was there that I began leaning more into the humanitarian roles
            that not only presented an opportunity but directly correlated to my
            own upbringing and lived experiences.
          </h3>
          <h3> 
            This affinity for mathematics
            and problem-solving, and newfound passion in international
            developmental plights all coalesced during an internship opportunity, my junior year,
            at Baobab Experience in Rome, Italy. While there I began to think
            about the different frameworks to be utilised in order to tackle
            world problems; in my department this was specific to mathematical
            and technological techniques to be deployed as a tool to model the
            larger world.
          </h3>
          <br></br>
          <br></br>

          <h1>What am I doing now? </h1>
      
          <h3>
            For the subsequent years, I have taken
            the time to solidify my competency as a developer. I have built one
            of Jamaica’s first COVID-19 trackers using ReactJS and started a
            regional online learning platform, covering the syllabus for mathematics and physics 
            throughout the Caribbean region to aid students whose education 
            has been affected by COVID.
            Currently, I work as a Smart Contract/ Blockchain engineer for decentralized 
            autonomous organizations that are based primarily on the Avalanche and Arbitrum network.
          </h3>
          <h3>
            Besides the aforementioned, I also act as an advocate for the Women in
            STEM program for young women from the Upper Valley regions of New Hampshire and Vermont, as well as 
            in Jamaica through an islandwide mentorship program, PiSocieties.
          </h3>
        </div>
      </div>
    </div>
  );
}

export default About;
