import React from 'react';
import './Card.css';
import LanguageIcon from '@mui/icons-material/Language';
import CodeIcon from '@mui/icons-material/Code';

function Card({src, description, active1, active2, props1, props2}) {
    return (
        <div className="card" 
        >
            <div className="card__img">
                <img src = {src} alt='' /> 
            </div>
            
            <div className="card__content"> 
                <h3>{description}</h3>
                <div className="fold">
                    <div className={`widgets1 ${active1 && "widgets--selected1"}`}>
                        <LanguageIcon onClick = {props1} style={{marginRight:"15px"}}/>
                    </div>

                    <div className={`widgets2 ${active2 && "widgets--selected2"}`}>
                        <CodeIcon onClick={props2} />
                    </div>
                </div>
              
 
            </div>
            
            
        </div>
    )
}

export default Card
