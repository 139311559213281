import React, { useState } from "react";
import "./ComputerScience.css";
import SectionButton from "./SectionButton";
import Web from "./Web";
import Mobile from "./Mobile";

function ComputerScience() {
  const [type, setType] = useState("Web");
  return (
    <div>
      <div className="cs__object">
        <SectionButton
          onClick={(e) => setType("Web")}
          active={type === "Web"}
          title="Web"
        />
        <SectionButton
          onClick={(e) => setType("Mobile")}
          active={type === "Mobile"}
          title="Mobile"
        />
      </div>

      <div>
        {type === "Web" && <Web />}
        {type === "Mobile" && <Mobile />}
      </div>
    </div>
  );
}

export default ComputerScience;
