import React, {useState, useEffect} from 'react';
import './SideMenu.css';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import GitHubIcon from '@material-ui/icons/GitHub';

function SideMenu() {

    const[scrollPosition, setScrollPosition] = useState(0);
   

    var visible = "hide"

    const handleScroll = (e) =>{
        const position = window.pageYOffset;
        setScrollPosition(position);
     
    
        
    };

    const toggleMenu= () =>{
        visible = "show";
    }


    useEffect(() => {
        window.addEventListener('scroll', handleScroll, { passive: true });
    
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    
 


    return (
        
        <div onScroll={handleScroll}>
            {scrollPosition >= 1080 && toggleMenu()} 


             <div id ="flyoutMenu" className={visible}>
            
                <div className ="home__symbol">
                    <a href="#home">
                    &lt; / &gt;
                    </a>
                </div>
              
                <a href = "#about" className="linkk">< h1>About</h1></a>


                <a href ="#resume" className="linkk"><h1>Resumé</h1></a>


                <a href ="#portfolio" className="linkk"><h1>Portfolio</h1></a>


                <a href ="#skills" className="linkk"><h1>Skills</h1></a>


                <a href ="#contact" className="linkk"><h1>Contact</h1></a>

                <a href ="https://www.linkedin.com/in/abigailtcameron/"><LinkedInIcon/></a>
                <a href ="https://github.com/AbigailTCameron"><GitHubIcon/></a>

    

        </div>

           
        </div>
        
             
    )
}

export default SideMenu
