import React from "react";
import {
  Timeline,
  TimelineItem,
  TimelineOppositeContent,
  TimelineSeparator,
  TimelineDot,
  TimelineConnector,
  TimelineContent,
} from "@material-ui/lab";
import { Typography, Paper } from "@material-ui/core";


function Education() {

  return (
    <div>
      <Timeline align="alternate">
        <TimelineItem>
          <TimelineOppositeContent>
            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
              2016-2020
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot style={{backgroundColor: "#0058f7"}}>
           
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}}>
              <Typography variant="h6" component="h1" style={{fontWeight: "900", fontFamily: "'Courier New', Courier, monospace"}}>
                BA Mathematics, Dartmouth College, USA
              </Typography>

              <h4
                style={{
                  fontStyle: "italic",
                  fontWeight: "700",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                Major: Mathematics
              </h4>
              <h4
                style={{
                  fontStyle: "italic",
                  fontWeight: "700",
                  marginTop: "5px",
                  marginBottom: "5px",
                }}
              >
                Minor: Philosophy
              </h4>

              <Typography component="span" style={{fontFamily: "'Courier New', Courier, monospace", color:"#777", fontWeight: "700"}}>
                Research Paper
                <Typography variant="body2" display="inline" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700"}}>
                  : Simulated mathematical models, in MATLAB, for the analysis
                  of the hierarchical organisation of tumour initiating cells in
                  response to chemical and radioactive treatment.
                </Typography>
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent>
            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
              2017
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot style={{backgroundColor:"#0058f7"}}>
           
            </TimelineDot>
            <TimelineConnector />
          </TimelineSeparator>
          <TimelineContent>
            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}}>
              <Typography variant="h6" component="h1" style={{fontWeight: "900", fontFamily: "'Courier New', Courier, monospace"}}>
                Italian Langauge Study Abroad, Italy
              </Typography>
              <Typography component="span" variant="body2" display="inline" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight: "700", color:"#777"}}>
                Immersive program aimed at thorough grounding in spoken and
                written Italian.
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>

        <TimelineItem>
          <TimelineOppositeContent>
            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
              2009-2016
            </Typography>
          </TimelineOppositeContent>
          <TimelineSeparator>
            <TimelineDot style={{backgroundColor:"#0058f7"}}>
            
            </TimelineDot>
          </TimelineSeparator>
          <TimelineContent>
            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}}>
              <Typography variant="h6" component="h1" style={{fontWeight: "900", fontFamily: "'Courier New', Courier, monospace"}}>
                Pre-university, Campion College, Jamaica
              </Typography>
              <Typography component="span" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight:"700"}}>
                Associate Degree (Pre-med):
              </Typography>
              <Typography component="span" variant="body2" display="inline" style={{fontFamily: "'Courier New', Courier, monospace", fontWeight: "700", color:"#777"}}>
                A levels: Mathematics: AAA, Physics: AAA, Biology: AAA,
                Chemistry: AAA
              </Typography>
            </Paper>
          </TimelineContent>
        </TimelineItem>
      </Timeline>
    </div>
  );
}

export default Education;
