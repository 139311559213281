import React from 'react';
import "./Projects.css";
import Card from './Card';

function Projects() {
  return (
    <div className="projects__object">

      <Card
        props1 = {() => window.location.href = "https://fictivelabs.com/"}
        active1 = {true}
        src="fictive.png"
        description="Fictive Labs"
      />

       <Card
        props1 = {() => window.location.href = "https://launchlearningplatform.com/"}
        active1 = {true}
        src="launch.jpg"
        description="Launch Learning Platform"
      />
    </div>
  )
}

export default Projects