import React, {useState} from 'react';
import './Portfolio.css';
import PortfolioButton from './PortfolioButton';
import Mentorship from './Mentorship';
import ComputerScience from './ComputerScience';
import Research from './Research';
import Projects from './Projects';

function Portfolio() {
    const[title, setTitle] = useState("Projects");


    return (
        <div className ="portfolio" id="portfolio">

            <h1>Portfolio</h1>

            <div className ="portfolio__button">
                <PortfolioButton 
                    onClick={(e) => setTitle("Projects")}
                    title="Projects"
                    active={title==="Projects"}
                />

                <PortfolioButton 
                    onClick ={(e) => setTitle("Computer Science")}
                    title="Computer Science"
                    active={title==="Computer Science"}
                />
                 
                <PortfolioButton 
                    onClick ={(e) => setTitle("Mentorship")}
                    title="Mentorship"
                    active={title==="Mentorship"}
                />
               
                <PortfolioButton 
                    onClick ={(e) => setTitle("Research")}
                    title="Research"
                    active={title==="Research"}
                />

            </div>

            <div className="portfolio__info">
                {title ==="Projects" && <Projects /> }
                {title ==="Mentorship" && <Mentorship /> }
                {title ==="Computer Science" && <ComputerScience />}
                {title ==="Research" && <Research/>}
            </div>

            
        </div>
    )
}

export default Portfolio