import React, {useState} from 'react';
import './Skills.css';
import Code from './Code';
import Software from './Software';
import Other from './Other';
import SkillObject from './SkillObject';

function Skills() {
    const [skill, setSkill] = useState("Coding");


 


    return (

        <div className="skills" id="skills">
            <h1>Skills</h1>

            <div className ="skills_container">
                <div className="content__divider">

                    <SkillObject
                        skill ="Coding"
                        onClick = {(e) => setSkill("Coding")}
                        active ={skill ==="Coding"}
                    /> 

                    <SkillObject
                        skill ="Software"
                        onClick = {(e) => setSkill("Software")}
                        active ={skill ==="Software"}
                    /> 

                    <SkillObject
                        skill ="Other"
                        onClick = {(e) => setSkill("Other")}
                        active ={skill ==="Other"}
                    /> 

                </div>


                <div className ="skill__select">
                    {skill === "Coding" && <Code /> }
                    {skill ==="Software" && <Software />}
                    {skill ==="Other" && <Other />}
                </div>

                    
            </div>



            

        </div>
     
    )
}

export default Skills