import React from 'react';
import { Typography, Paper } from '@material-ui/core';
import {Timeline, TimelineItem, TimelineOppositeContent, TimelineSeparator, TimelineDot, TimelineConnector, TimelineContent} from '@material-ui/lab';


function Honour() {
    return (
        <div>
            <Timeline align="alternate">


                <TimelineItem>
                        <TimelineOppositeContent>
                                <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
                                    2023
                                </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                                <TimelineDot style={{backgroundColor: "#0058f7"}}>
                                    
                                </TimelineDot>
                                <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                                <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}} >
                                    <h3 style={{fontWeight: "900", marginBottom:"5px"}}>Dartmouth's Changemakers Feature</h3>
                                    <a
                                        href="https://dartmouthalumnimagazine.com/articles/dartmouth-king-scholars?fbclid=IwAR0x9Ksx2JKq6F4twYGWC22Upr0L-BeEw6jiFcAjBHy1tFvc4DDOJdR1y9c"
                                        style={{ fontStyle: "italic", fontWeight: "500", color: "#0058f7" }}
                                    >
                                        Dartmouth Alumni Magazine
                                    </a>                                  
                            
                                </Paper>
                        </TimelineContent>
                </TimelineItem>

                <TimelineItem>
                        <TimelineOppositeContent>
                                <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
                                    2016-2020
                                </Typography>
                        </TimelineOppositeContent>
                        <TimelineSeparator>
                                <TimelineDot style={{backgroundColor: "#0058f7"}}>
                                    
                                </TimelineDot>
                                <TimelineConnector />
                        </TimelineSeparator>
                        <TimelineContent>
                                <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}} >
                                    <h3 style={{fontWeight: "900", marginBottom:"5px"}}>King Scholar</h3>
                                    <h4 style={{fontStyle: "italic", fontWeight: "500", marginBottom:"5px"}}>Dartmouth College</h4>
                                <Typography component="span" variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", color:"#777", fontWeight: "700"}}>
                                A program that selects low-income students from developing nations who are interested in alleviating poverty in their home countries. 
                                At Dartmouth, King Scholars receive 4-year scholarships and extensive mentoring and leadership training.
                                </Typography>   
                            
                                </Paper>
                        </TimelineContent>
                </TimelineItem>



                <TimelineItem>
                    <TimelineOppositeContent>
                            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
                                2016-2017
                            </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                            <TimelineDot style={{backgroundColor: "#0058f7"}}>
                                
                            </TimelineDot>
                            <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}} >
                                <h3 style={{ fontWeight: "900", marginBottom:"5px"}}>Great Issues Scholar</h3>
                                <h4 style={{ fontStyle: "italic", fontWeight: "500", marginBottom:"5px"}}>Dartmouth College</h4>
                            <Typography component="span" variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", color:"#777", fontWeight: "700"}}>
                                A year-long series of highly interactive events aimed at enhancing one’s understanding of the world and current international events.
                            </Typography>   
                        
                            </Paper>
                    </TimelineContent>
                </TimelineItem>



                <TimelineItem>
                    <TimelineOppositeContent>
                            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
                                2015
                            </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                            <TimelineDot style={{backgroundColor: "#0058f7"}}>
                                
                            </TimelineDot>
                            <TimelineConnector />
                    </TimelineSeparator>
                    <TimelineContent>
                            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}} >
                                <h3 style={{fontWeight: "900", marginBottom:"5px"}}>  Ranked number three in the region</h3>
                                <h4 style={{fontStyle: "italic", fontWeight: "500", marginBottom:"5px"}}>Pure Mathematics</h4>
                            <Typography component="span" variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", color:"#777", fontWeight: "700"}}>
                                Ranked as the number three student in the region by the Caribbean Examination Council for CAPE (A level) for Pure Mathematics.
                            </Typography>   
                        
                            </Paper>
                    </TimelineContent>
                </TimelineItem>



                <TimelineItem>
                    <TimelineOppositeContent>
                            <Typography style={{fontFamily: "'Courier New', Courier, monospace"}}>
                                2014
                            </Typography>
                    </TimelineOppositeContent>
                    <TimelineSeparator>
                            <TimelineDot style={{backgroundColor: "#0058f7"}}>
                        
                            </TimelineDot>
                    </TimelineSeparator>
                    <TimelineContent>
                            <Paper style={{padding:"20px", backgroundColor: "hsla(0,0%,76.9%,.14901960784313725)", outline: "1px solid white", borderRadius:"25px"}} >
                                <h3 style={{fontWeight: "900", marginBottom:"5px"}}>  Ranked as the number one student in the Caribbean region</h3>
                                <h4 style={{fontStyle: "italic", fontWeight: "500", marginBottom:"5px"}}>Mathematics</h4>
                            <Typography component="span" variant="body2" style={{fontFamily: "'Courier New', Courier, monospace", color:"#777", fontWeight: "700"}}>
                                Ranked as the number one student in the Caribbean by the Caribbean Examination Council for CSEC (O level) Mathematics.
                            </Typography>   
                        
                            </Paper>
                    </TimelineContent>
                </TimelineItem>




            </Timeline>
            
        </div>
    )
}

export default Honour
