import React from 'react';
import Iframe from 'react-iframe';
import './SlideCard.css';

function SlideCard({url, description}) {
    return (
        <div className="slidecard">
            <div className="slidecard__wrapper">
                <Iframe url={url}
                    width="100%"
                    height="100%"
                />
            </div>
            

            <div className="slidecard__content"> 
                <h3>{description}</h3>
            </div>
            
        </div>
    )
}

export default SlideCard
