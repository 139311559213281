import React from 'react';
import './Mentorship.css';
import Card from './Card';
import VideoCard from './VideoCard';

function Mentorship() {
    return (
        <div className ="mentor__object">

            <VideoCard
                url = "https://www.youtube.com/watch?v=LX2Wlu_nhD8&ab_channel=LaunchLearningPlatform"
                description = "Launch Learning Platform"
                props1 = {() => window.location.href="https://launchlearningplatform.com/"}
                active1 = {true}  
            /> 

            <Card
                props1 ={() => window.location.href="https://home.dartmouth.edu/events/event?event=33861#.YGZgIWRKhb8"}
                active1 = {true}
                src ='/skday.jpeg'
                description = "Sonia Kovalevsky Day at Dartmouth College"
            /> 

            <Card
                props1 = {() => window.location.href="https://dickey.dartmouth.edu/programs/global-studies/undergraduate-fellowship-programs/great-issues-scholars"}
                active1 = {true}
                src ='/gis.jpg'
                description = "Great Issues Scholar Program at Dartmouth College"
            /> 
            
        </div>
    )
}

export default Mentorship