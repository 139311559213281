import React from 'react'
import './Software.css'

function Software() {
    return (
        <div className = "software">

            <div className = "software__select">

                <div className ="software__individual">
                    <h3>Google Cloud Platform <span class="level">••••</span></h3>
                    <h3>MATLAB <span class="level">•••</span></h3>
                    <h3>Google Analytics <span class="level">••••</span></h3>
                    <h3>Android Studio <span class="level">•••</span></h3>
                    <h3>Wordpress <span class="level">•••</span></h3>
                    <h3>MySQL <span class="level">••</span></h3>
                </div>


            </div>
            
        </div>
    )
}

export default Software
