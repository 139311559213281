import React from "react";
import Card from "./Card";
import "./Web.css";

function Web() {
  return (
    <div className="web__app">
      <Card 
        props1 = {() => window.location.href = "https://solana-store-six.vercel.app/"}
        props2 = {() => window.location.href = "https://github.com/AbigailTCameron/solana-store"}
        active1 = {true}
        active2 = {true}
        src="/solana.gif"
        description="Solana Pay Store"
      />
      <Card 
        props1 = {() => window.location.href = "https://wave-portal-frontend-brown.vercel.app/"}
        props2 = {() => window.location.href = "https://github.com/AbigailTCameron/wave-portal"}
        active1 = {true}
        active2 = {true}
        src="/Web3 Wave Portal.png"
        description="Web3 Wave Portal"
      
      />
      <Card
        props2 = {() => window.location.href = "https://github.com/AbigailTCameron/NFTMarketPlace"}
        active2 = {true}
        src="/market.png"
        description="NFT Marketplace"
      />
      <Card
        props1 = {() => window.location.href = "https://amazon-clone-ochre.vercel.app/"}
        props2 = {() => window.location.href = "https://github.com/AbigailTCameron/Amazon-Clone"}
        active1 = {true}
        active2 = {true}
        src="/amazon.png"
        description="Amazon Clone"
      />
      <Card
        props1 = {() => window.location.href = "https://huluclone-psi.vercel.app/"}
        props2 = {() => window.location.href = "https://github.com/AbigailTCameron/huluclone"}
        active1 = {true}
        active2 = {true}
        src="/hulu.png"
        description="Hulu Clone"
      />

      <Card
        props1 = {() => window.location.href = "https://covid-19-tracker-d6357.web.app/"}
        active1 = {true}
        src="/covid.jpeg"
        description="COVID-19 Tracker"
      />
    </div>
  );
}

export default Web;
