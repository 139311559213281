import React from "react";
import "./VideoCard.css";
import ReactPlayer from "react-player";
import LanguageIcon from '@mui/icons-material/Language';
import CodeIcon from '@mui/icons-material/Code';

function VideoCard({ description, url, active1, active2, props1, props2 }) {
  return (
    <div className="videocard">
      <div className="video__wrapper">
        <ReactPlayer url={url} controls width="100%" height="100%"/>
      </div>

      <div className="videocard__content">
        <h3>{description}</h3>
        <div className ="fold">
          <div className={`widgets1 ${active1 && "widgets--selected1"}`}>
            <LanguageIcon onClick = {props1} style={{marginRight:"15px"}}/>
          </div>

          <div className={`widgets2 ${active2 && "widgets--selected2"}`}>
            <CodeIcon onClick={props2} />
          </div>
        </div>
        
      </div>
    </div>
  );
}

export default VideoCard;
